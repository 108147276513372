import { delete_, get, post } from '../helpers/caller'
import { ApplicationType } from '../dictionaries/applicationType'
import { createSequentialExecutor } from '../helpers'

const makeSequential = createSequentialExecutor()

export const user = {
  domain: 'user',
  conversion(data: any) {
    return post(user.domain, user.conversion, data)
  },
  updateConversion(data: any) {
    return post(user.domain, user.conversion, data)
  },
  roles() {
    return get(user.domain, user.roles)
  },
  draft(documentType: ApplicationType, current: string | undefined) {
    return makeSequential(get)(user.domain, user.draft, {
      documentType,
      current,
    })
  },
  saveDraft(document: any) {
    return makeSequential(post)(user.domain, user.draft, document, {}, true)
  },
  deleteDraft(draftId: string | undefined, loanAppId: string | undefined) {
    return delete_(user.domain, user.draft, { draftId, loanAppId })
  },
  updatePlan(invoice_id: string[], plan: any, loanAppId?: string) {
    return post(user.domain, user.updatePlan, {
      invoice_id,
      plan,
      loanAppId,
    })
  },
  builderCount() {
    return get(user.domain, user.builderCount)
  },
  sendVerificationEmail() {
    return get(user.domain, user.sendVerificationEmail)
  },
  verifyEmail(code: string, firebaseId: string) {
    return post(user.domain, user.verifyEmail, { code, firebaseId })
  },
  checkEmailIsVerified() {
    return get(user.domain, user.checkEmailIsVerified)
  },
  logout() {
    return post(user.domain, user.logout)
  },
}
