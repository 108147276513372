import { Instance, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'

export enum EConnectorSyncType {
  NONE = 'None',
  TO_BLUE_TAPE = 'ToBlueTape',
  FROM_BLUE_TAPE = 'FromBlueTape',
  BOTH = 'Both',
}

export enum EConnectorType {
  Generic = 'Generic',
}

export const QuickBooksSettings = types.model({
  invoice_import_enabled: types.maybeNull(types.boolean),
})

export const Connector = types.compose(
  types.model('Connector', {
    document_id: types.maybeNull(types.string),
    integration_id: types.maybeNull(types.string),
    connector_type: types.maybeNull(types.string),
    customer_id: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    source_modified_date: types.maybeNull(types.string),
    quote_ref_number: types.maybeNull(types.string),
    amount_due: types.maybeNull(types.number),
    sync_type: types.optional(
      types.enumeration<EConnectorSyncType>(Object.values(EConnectorSyncType)),
      EConnectorSyncType.NONE,
    ),
    project: types.optional(types.frozen(), {}),
  }),
  editableModel(),
)

export interface IConnectorModel extends Instance<typeof Connector> {}
