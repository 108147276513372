import { post } from '../helpers/caller'

const domain = 'guest'

export interface IGuestCardRequest {
  invoiceId: string
  cardToken: string
  firstName: string
  lastName: string
  phoneNumber: string
  contactPhone: string // TODO: VK: Remove
  addressLine1: string
  addressLine2: string
  city: string
  stateCode: string
  zipCode: string
  accountType?: string
}

// TODO: VK: Clarify
export interface IGuestPaymentInfo {
  card: {
    last4: string
    network: string
    type: string
    bankName: string
  }
  bankAccount: {
    id: string
    name: string
    accountholderName: string
    billingAddress: {
      addressLine1: string
      addressLine2: string
      city: string
      stateCode: string
      zipCode: string
    }
  }
  payment: {
    invoiceAmount: number
    fee: number
    totalAmount: number
  }
}

export interface IGuestPaymentRequest {
  invoiceId: string
  company: {
    name: string
    isBusiness: boolean
  }
  user: {
    firstName: string
    lastName: string
    phone: string
    email: string
  }
  payment: {
    invoiceAmount: number
    fee: number
    totalAmount: number
  }
  bankAccountId: string
}

export const guest = {
  paymentInfo(body: IGuestCardRequest): Promise<IGuestPaymentInfo> {
    return post(domain, guest.paymentInfo, { body })
  },

  payInvoice(body: IGuestPaymentRequest) {
    return post(domain, guest.payInvoice, { body })
  },
}
